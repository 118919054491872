<template>
<v-app>
	<v-row :class="($vuetify.theme.dark)? 'dark' : 'light'" align="center" justify="center">
		<v-col  class="text-center" cols="12">
			<h1 class="text-h3 font-weight-thin mb-4 ">
				LCFreelancer
			</h1>
			<h4 class="subheading">
				Construyendo...
			</h4>
			<br/>
			<v-btn icon @click="switchTheme">
				<v-icon>mdi-theme-light-dark</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</v-app>
</template>

<script>
export default {
	name: 'App',

	data: () => ({
		//
	}),
	methods: {
		switchTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		}
	},
	mounted() {
		var hr = (new Date()).getHours();

		var isDay = hr > 6 && hr < 20;

		if (isDay){
			this.$vuetify.theme.dark = false;
		} else {
			this.$vuetify.theme.dark = true;
		}
	}
};
</script>

<style scoped>
.v-application{
	margin: -12px !important;
}
.dark {
	background-image: url("/back.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.light {
	background-image: url("/back_w.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
